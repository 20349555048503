import React, { useState, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { render } from 'react-dom';
import {AppVersion} from '@/kyna/lib/StandardReferences'
import {AiFillYoutube} from 'react-icons/ai'
import Image from 'next/image'
import Link from 'next/link'

// import AppLogo from '../../../public/assets/img/app-logo.png';
import AppLogo from '../../public/assets/img/app-logo.png';
// import FBicon from '../../public/assets/img/facebook.png';
// import IGicon from '../../public/assets/img/instagram.png';
// import Twittericon from '../../public/assets/img/twitter.png';

import FBicon from 'public/assets/img/icons/facebook-icon.svg';
import Twittericon from 'public/assets/img/icons/twitter-icon.svg';
import IGicon from 'public/assets/img/icons/instagram-icon.svg';
import YTicon from 'public/assets/img/icons/youtube-icon.svg';
import TikTokicon from 'public/assets/img/icons/tiktok-icon.svg';

import LocationIcon from "../../public/assets/img/icons/location-icon.svg";
import ClockIcon from "../../public/assets/img/icons/clock-icon.svg";
import EmailIcon from "../../public/assets/img/icons/email-icon.svg";


function Footer(props) {

    return (
        <div className="">
            <section id="footer-section" className='xl:container mx-auto px-8 mt-16 md:mt-28 mb-10'>
                {/* <div className='flex-1 items-center'> */}
                    <div className="grid md:grid-cols-4 grid-cols-1 gap-y-10 md:gap-2 mb-20">
                        <div className="col-span-1">
                            <Link href="/"><a className='app-logo max-w-[100px] md:mx-0 mx-auto block'><Image src={AppLogo} alt="AppLogo" /></a></Link>
                        </div>
                        <div className="col-span-3">
                            <div className='grid md:grid-cols-3 grid-cols-1 md:gap-10 gap-x-0 gap-y-5 md:max-w-full max-w-[350px] mx-auto'>
                                <div className='col-span-3 sm:col-span-2 md:col-span-1 text-left lg:col-span-1'>
                                    <h4 className="font-medium mb-5 text-base text-blue">Quicklinks</h4>
                                    <ul>
                                        <li className="mb-5"><Link href="/about"><a className="text-gray-500">About</a></Link></li>
                                        <li className="mb-5"><Link href="/careers"><a className="text-gray-500">Careers</a></Link></li>
                                        <li className="mb-5"><Link href="/membership"><a className="text-gray-500">Be a Member</a></Link></li>
                                        <li className="mb-5"><Link href="/donations"><a className="text-gray-500">Donate Now</a></Link></li>
                                    </ul>
                                </div>
                                <div className='col-span-3 sm:col-span-2 lg:col-span-1 text-left'>
                                    <h4 className="font-medium mb-5 text-base text-blue">Programs and Services</h4>
                                    <ul>
                                        <li className="mb-5"><Link href="/eard"><a className="text-gray-500">Education, Advocacy and Research</a></Link></li>
                                        <li className="mb-5"><Link href="/cisd"><a className="text-gray-500">Clinical and Intervention</a></Link></li>
                                    </ul>
                                </div>
                                <div className='col-span-3 sm:col-span-2 lg:col-span-1 text-left'>
                                    <div className="flex mb-3">
                                        <div className="w-[16px] mt-2">
                                            <Image src={LocationIcon} alt="Location" />
                                        </div>
                                        <div className="pl-3">
                                        <h2 className="text-blue text-sm mb-1">����������Ƶ, Inc.</h2>
                                        <p>18 East Ave, Diliman, Quezon City, <br />1100 Metro Manila</p>
                                        </div>
                                    </div>
                                    <div className="flex mb-3">
                                        <div className="w-[14px] mt-[3px]">
                                        <Image src={EmailIcon} alt="Email" />
                                        </div>
                                        <div className="pl-3">
                                        <Link href="mailto:hello@pmha.org.ph"><a className="text-black/70">hello@pmha.org.ph</a></Link>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <div className="w-[16px] mt-[2px]">
                                        <Image src={ClockIcon} alt="Clock" />
                                        </div>
                                        <div className="pl-3">
                                        <p>Office Schedule:</p>
                                        <p>Monday - Friday (8:00AM - 5:00PM)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* footer */}
                    <div className='grid grid-cols-12 gap-4'>
                        <div className="col-span-12 lg:col-span-10 items-center text-center lg:text-start mb-7 lg:mb-0">
                            <p className="uppercase px-2 text-sm text-center lg:text-left lg:inline tracking-wider">����������Ƶ {new Date().getFullYear()}. All Rights Reserved. Powered by <Link href="https://kynatech.ph/"><a target="_blank">kynatech.ph</a></Link></p>
                            <span className="uppercase text-sm tracking-wider"><Link href="/privacy-policy"><a className="text-gray-500 p-2">Privacy Policy</a></Link>•</span>
                            <span className="uppercase text-sm tracking-wider"><Link href="/terms-and-conditions"><a className="text-gray-500 p-2">Terms and Conditions</a></Link></span>
                        </div>


                        <div className="col-span-12 row lg:col-span-2">
                            <ul className="footer-social-icon text-center lg:text-right flex items-center justify-center xl:justify-end gap-x-5">
                                <li className="!w-[8px]"><a href="https://www.facebook.com/����������Ƶofficial" target={"_blank"} rel="noreferrer"><Image src={FBicon} alt="Facebook"/></a></li>
                                <li className=""><a href="https://twitter.com/����������Ƶofficial" target={"_blank"} rel="noreferrer"><Image src={Twittericon} alt="Twitter"/></a></li>
                                <li className=""><a href="https://www.instagram.com/pmhaofficial/" target={"_blank"} rel="noreferrer"><Image src={IGicon} alt="Instagram"/></a></li>
                                <li className=""><a href="https://www.youtube.com/channel/UCvZjwCE8FucFXNrcGjgPz_w" target={"_blank"} rel="noreferrer"><Image src={YTicon} alt="Youtube"/></a></li>
                                <li className="!w-[14px]"><a href="https://www.tiktok.com/@pmhaofficial" target={"_blank"} rel="noreferrer"><Image src={TikTokicon} alt="Tiktok"/></a></li>
                            </ul>
                        </div>
                    </div>

                    
            </section>
        </div>
    )
}

export default Footer;
